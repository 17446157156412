@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Poppins Light'), local('Poppins-Light'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLDz8Z1JlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v9/pxiEyp8kv8JHgFVrJJnedw.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Poppins Medium'), local('Poppins-Medium'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLGT9Z1JlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLEj6Z1JlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLCz7Z1JlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Audiowide Regular'), local('Audiowide-Regular'), url(https://fonts.gstatic.com/s/audiowide/v8/l7gdbjpo0cum0ckerWCdmA_L.ttf) format('truetype');
}
body {
  min-height: 100vh;
  height: 100%;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url('../img/backgrounds/test.jpg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow-x: hidden;
}
body.no-scroll {
  overflow: hidden;
  max-height: 100vh;
}
body ul,
body ol,
body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
  padding: 0;
}
body a {
  text-decoration: none !important;
}
body .container-fluid {
  max-width: 1240px;
}
body .mb-10 {
  margin-bottom: 10px;
}
body p {
  color: #212529;
  font-size: 14px;
  line-height: 24px;
}
body .logo-img {
  position: fixed;
  z-index: -1;
  top: 25%;
  right: -25%;
  height: 60%;
}
.btn {
  border: none;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.5);
  border-radius: 0;
  padding: 10px 20px;
  color: #444;
  font-size: 14px;
  font-weight: 500;
  transition: all 200ms ease-in;
}
.btn.btn-default {
  background: #fff;
}
.btn.btn-default:hover {
  border: none;
  box-shadow: none;
  background-color: #444;
  color: #fff;
}
.system-alerts {
  width: 100%;
  text-align: center;
}
.system-alerts .alert {
  border-radius: 0;
  border: none;
  height: 60px;
  display: flex;
  align-items: center;
  opacity: 0.9;
  transition: all 0.4s;
}
.system-alerts .alert .alert-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.system-alerts .alert .alert-inner .img-hidden {
  visibility: hidden;
  opacity: 0;
  height: 18px;
}
.system-alerts .alert .alert-inner p {
  color: #fff;
  padding: 0 50px;
}
.system-alerts .alert .alert-inner button {
  color: #fff;
  opacity: 1;
  font-weight: 300;
  float: none;
}
.system-alerts .alert .alert-inner button img {
  height: 18px;
}
.system-alerts .alert.alert-success {
  background: #76ad21;
}
.system-alerts .alert.alert-info {
  background: #218ead;
}
.system-alerts .alert.alert-warning {
  background: #c52b20;
}
.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.modal-service-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.modal-service-wrapper .modal-service {
  padding: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.modal-service-wrapper .modal-service .service-inner {
  background: #fff;
  width: 700px;
  max-width: 100%;
  padding: 50px 25px 25px 25px;
  text-align: center;
  position: relative;
}
.modal-service-wrapper .modal-service .service-inner .service-button {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar {
  display: block;
  position: absolute;
  width: 20px;
  height: 2px;
  background: #222;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-content .heading {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.modal-service-wrapper .modal-service .service-inner .service-content .btn {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons .btn-link {
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}
.heading {
  text-align: center;
  margin-bottom: 40px;
}
.heading h2 {
  font-size: 42px;
  font-weight: 500;
  color: #444;
}
.heading p {
  font-weight: 500;
  color: #999;
  margin-top: 10px;
  font-size: 16px;
}
.product-wrapper {
  display: flex;
}
.product-wrapper .tab-content {
  display: flex;
  justify-content: space-between;
  padding: 0;
  width: 100%;
}
.product-wrapper .tab-pane {
  width: 100%;
}
.product-wrapper .wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.product-wrapper .product {
  margin-top: 3%;
  width: 22.5%;
  height: 100%;
  transition: all 0.4s;
}
.product-wrapper .product:hover .bg-product .product-image {
  border: 3px solid #fff;
}
.product-wrapper .product.offer-more .product-image {
  width: 100%;
  padding-top: 100%;
  position: relative;
  text-align: center;
  transition: all 0.4s;
  border: 3px solid #ddd;
}
.product-wrapper .product.offer-more .product-image p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 28px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.3);
  line-height: 28px;
}
.product-wrapper .product.offer-more .product-image p span {
  font-size: 14px;
  color: #212529;
  font-weight: 400;
}
.product-wrapper .product.offer-more:hover .product-image {
  border: 3px solid #fff;
}
.product-wrapper .product .bg-product .product-image {
  border: 3px solid #ddd;
  transition: all 0.4s;
  width: 100%;
  padding-top: 100%;
  background-size: cover;
}
.product-wrapper .product .product-description .name {
  font-size: 17px;
  margin-top: 20px;
  font-weight: 500;
  color: #444;
}
.product-wrapper .product .product-description .price {
  margin-top: 5px;
  display: flex;
  align-items: center;
}
.product-wrapper .product .product-description .price img {
  height: 18px;
  margin-right: 8px;
  width: auto;
}
.product-wrapper .product .product-description .price p {
  font-size: 16px;
  font-weight: 500;
  color: #999;
}
.product-wrapper .product .product-description .btn {
  font-size: 14px;
  padding: 3px 20px;
  margin: 10px 0;
}
.product-wrapper .product.box-hidden {
  margin: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
}
.nav-pills {
  align-items: center;
  flex-wrap: wrap;
}
.nav-pills h3 {
  font-size: 15px;
  font-weight: 500;
  color: #444;
}
.nav-pills .nav-item .nav-button {
  color: #444;
  font-size: 14px;
  text-decoration: none;
  font-weight: 600;
  opacity: 0.4;
  display: block;
  padding: 10px 15px;
  margin: 5px;
  transition: opacity 150ms ease-in;
  box-sizing: border-box;
  border: 1px solid transparent;
}
.nav-pills .nav-item .nav-button:nth-of-type(1) {
  margin: 5px 5px 5px 0;
}
.nav-pills .nav-item .nav-button:hover {
  opacity: 1;
}
.nav-pills .nav-item .nav-button.active {
  opacity: 1;
  border: 1px solid #444;
}
nav.section-header {
  position: relative;
  z-index: 200;
}
nav.section-header .container:before {
  display: none;
}
nav.section-header .header-flexbox {
  padding: 32px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav.section-header .header-flexbox .flexbox-logo img {
  width: 200px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
  position: absolute;
  display: none;
  width: 100%;
  top: 20px;
  left: 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:before,
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:after {
  content: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  background: #222;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
  display: flex;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
  font-size: 15px;
  font-weight: 500;
  color: #555;
  transition: color 200ms ease-in;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a:hover {
  color: #dfaf6c;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a.active {
  color: #dfaf6c;
}
@media (max-width: 576px) {
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    margin-right: 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a:last-child {
    margin-right: 0;
  }
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:not(:last-child) {
  margin-right: 35px;
}
nav.section-header .header-flexbox .search-input {
  position: absolute;
  right: 10px;
}
nav.section-header .header-flexbox .search-input form {
  display: flex;
  justify-content: center;
}
nav.section-header .header-flexbox .search-input form .input-search {
  border: none;
  background: none;
  border-bottom: 1px solid rgba(150, 10, 10, 0.2);
  width: 160px;
  display: none;
  outline: none;
}
nav.section-header .header-flexbox .search-input form .input-search.active {
  display: block;
  transition: all 270ms ease-in;
}
nav.section-header .header-flexbox .search-input form .input-search:focus {
  outline: none;
  width: 170px;
}
nav.section-header .header-flexbox .search-input form input::-webkit-input-placeholder {
  font-size: 13px;
}
nav.section-header .header-flexbox .search-input form .button-search {
  position: absolute;
  outline: none;
  border: none;
  background: transparent;
  left: 0;
  top: 0;
  right: 0;
  padding: 0;
  margin: 0;
  width: 15.5px;
  height: 15.5px;
  cursor: pointer;
  display: none;
}
nav.section-header .header-flexbox .search-input form .button-search.active {
  display: block;
  left: 145px;
}
nav.section-header .header-flexbox .search-input form .button-search.active img {
  height: 13px;
}
nav.section-header .header-flexbox .flexbox-menu-more {
  position: relative;
  display: flex;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-menu-more.hide {
  display: none;
}
nav.section-header .header-flexbox .flexbox-menu-more a {
  position: absolute;
  right: 0;
}
nav.section-header .header-flexbox .flexbox-menu-more a img {
  height: 15px;
}
nav.section-header .header-flexbox .flexbox-menu-more .search {
  position: relative;
  cursor: pointer;
  margin-right: 35px;
}
nav.section-header .header-flexbox .flexbox-menu-more .search .search-icon {
  transition: transform 200ms ease-in;
  width: 16px;
  height: 16px;
}
nav.section-header .header-flexbox .flexbox-menu-more .search .search-icon:hover {
  transform: scale(1.1);
}
nav.section-header .header-flexbox .flexbox-menu-more .search .search-icon.hover {
  display: none;
}
nav.section-header .header-flexbox .nav-button {
  padding: 10px;
  border: none;
  background: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  outline: none;
  display: none;
}
nav.section-header .header-flexbox .nav-button .button-bar {
  height: 2px;
  width: 24px;
  background: #dfaf6c;
  border-radius: 10px;
  transition: all 0.4s;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(2) {
  margin-top: 4px;
  width: 27px;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(3) {
  margin-top: 4px;
  width: 30px;
}
nav.section-header .header-flexbox .nav-button:hover .button-bar,
nav.section-header .header-flexbox .nav-button:focus .button-bar {
  width: 30px !important;
}
section.section-welcome .welcome-flexbox {
  display: flex;
  justify-content: space-between;
}
section.section-welcome .welcome-flexbox .content-wrapper {
  width: 50%;
  display: flex;
}
section.section-welcome .welcome-flexbox .content-wrapper.left {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 49%;
  min-height: 600px;
  padding: 30px;
}
section.section-welcome .welcome-flexbox .content-wrapper.left img {
  object-fit: cover;
  object-position: 20% 60%;
  height: 100%;
  width: 100%;
}
section.section-welcome .welcome-flexbox .content-wrapper.left h1 {
  font-size: 46px;
  line-height: 64px;
  font-weight: 700;
}
section.section-welcome .welcome-flexbox .content-wrapper.left .btn {
  align-self: flex-start;
}
section.section-welcome .welcome-flexbox .content-wrapper.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 48%;
}
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper {
  display: flex;
  justify-content: space-between;
  height: 48%;
  width: 100%;
}
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper .item {
  height: 100%;
}
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper .tiles {
  width: 47%;
  border: 3px solid #ddd;
}
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper .tiles,
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper .item {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 30px;
  position: relative;
  overflow: hidden;
}
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper .tiles.no-padding,
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper .item.no-padding {
  padding: 0;
}
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper .tiles .owl-carousel,
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper .item .owl-carousel,
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper .tiles .owl-stage,
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper .item .owl-stage,
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper .tiles .owl-stage-outer,
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper .item .owl-stage-outer,
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper .tiles .owl-item,
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper .item .owl-item {
  height: 100%;
}
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper .tiles img,
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper .item img {
  position: absolute;
  width: auto;
}
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper .tiles .btn,
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper .item .btn {
  position: relative;
  align-self: flex-start;
}
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper:first-of-type .tiles:first-of-type .item:first-of-type img {
  bottom: -3%;
  height: 100%;
  right: -10%;
}
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper:first-of-type .tiles:first-of-type .item:nth-of-type(2) img {
  bottom: -2%;
  height: 100%;
  right: -10%;
}
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper:first-of-type .tiles:first-of-type .item:nth-of-type(2) img {
  bottom: -2%;
  height: 50%;
  right: -10%;
}
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper:first-of-type .tiles:nth-of-type(2) img {
  height: 100%;
  right: -27%;
  top: 3%;
}
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper:nth-of-type(2) .tiles:first-of-type img {
  top: 5%;
  height: 90%;
  right: -7%;
}
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper:nth-of-type(2) .tiles:nth-of-type(2) .item:first-of-type img {
  height: 100%;
  right: -12%;
  top: 0;
}
section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper:nth-of-type(2) .tiles:nth-of-type(2) .item:nth-of-type(2) img {
  height: 100%;
  right: -12%;
  top: 0;
}
section.section-icons-work {
  padding-top: 80px;
}
section.section-icons-work .heading {
  text-align: center;
}
section.section-icons-work .icons-work-flexbox {
  display: flex;
  justify-content: space-between;
}
section.section-icons-work .icons-work-flexbox .flexbox-service {
  width: calc(100% / 3);
  text-align: center;
  padding: 0 50px;
}
section.section-icons-work .icons-work-flexbox .flexbox-service .icon {
  height: 60px;
  width: 60px;
  margin: 0 auto;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
section.section-icons-work .icons-work-flexbox .flexbox-service .icon img {
  height: 25px;
}
section.section-icons-work .icons-work-flexbox .flexbox-service h3 {
  font-size: 20px;
  margin-top: 20px;
  font-weight: 500;
  color: #444;
}
section.section-icons-work .icons-work-flexbox .flexbox-service p {
  margin-top: 18px;
  font-size: 14px;
  font-weight: 500;
  color: #999;
}
section.section-products {
  padding-top: 85px;
}
section.section-products .heading {
  margin-left: 20%;
  margin-right: 20%;
}
section.section-products .nav-pills-wrapper {
  margin-bottom: 20px;
}
section.section-products .nav-pills-wrapper p {
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 10px;
}
section.section-icons {
  padding-top: 75px;
}
section.section-icons .icons-flexbox {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}
section.section-icons .icons-flexbox .flexbox-service {
  width: calc(100% / 5);
  text-align: center;
}
section.section-icons .icons-flexbox .flexbox-service .icon {
  height: 60px;
  width: 60px;
  margin: 0 auto;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
section.section-icons .icons-flexbox .flexbox-service .icon img {
  height: 25px;
}
section.section-icons .icons-flexbox .flexbox-service h3 {
  font-size: 15px;
  margin-top: 20px;
  font-weight: 500;
  color: #444;
}
section.section-cta {
  padding-top: 100px;
}
section.section-cta .cta-inner {
  padding: 50px;
  background: #fff;
  position: relative;
  display: flex;
  align-items: center;
}
section.section-cta .cta-inner .text {
  width: 60%;
  padding-right: 50px;
}
section.section-cta .cta-inner .text h2 {
  font-size: 28px;
  font-weight: 500;
  color: #444;
}
section.section-cta .cta-inner .text h3 {
  font-size: 20px;
  margin-top: 10px;
  font-weight: 500;
  color: #666;
}
section.section-cta .cta-inner .text p {
  margin-top: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #999;
}
section.section-cta .cta-inner .img {
  width: 40%;
  height: 350px;
  margin-top: -10%;
}
section.section-cta .cta-inner .img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
section.section-cta .cta-inner .btn {
  margin-top: 30px;
}
section.section-recommended {
  padding-top: 80px;
}
section.section-recommended .heading {
  margin-left: 20%;
  margin-right: 20%;
}
section.section-recommended .carousel-content {
  display: flex;
}
section.section-recommended .carousel-content .owl-carousel .owl-stage-outer {
  padding: 0 2px;
}
section.section-recommended .carousel-content .owl-carousel .owl-nav {
  display: none;
}
section.section-recommended .carousel-content .owl-carousel .owl-stage {
  display: flex;
  padding: 0;
  width: 100%;
}
section.section-recommended .carousel-content .owl-carousel .owl-dots {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}
section.section-recommended .carousel-content .owl-carousel .owl-dots .owl-dot {
  padding: 0px 1px 50px 1px;
}
section.section-recommended .carousel-content .owl-carousel .owl-dots .owl-dot span {
  border-radius: 10px;
  width: 70px;
  height: 3px;
  margin: 0;
  transition: all 0.3s;
}
section.section-recommended .carousel-content .owl-carousel .owl-dot.active span {
  background: #dfaf6c;
}
section.section-recommended .carousel-content .product-wrapper,
section.section-recommended .carousel-content .wrapper {
  width: 100%;
}
section.section-recommended .carousel-content .item-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.1);
  transition: box-shadow 200ms ease-in;
}
section.section-recommended .carousel-content .item-content:hover {
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.2);
}
section.section-recommended .carousel-content .item-content .product-description {
  padding: 5px 8px;
  text-align: center;
  background-color: #f6f6f6;
}
section.section-recommended .carousel-content .item-content .product-description p {
  font-size: 13px;
  color: #444;
  margin-top: 8px;
}
section.section-recommended .carousel-content .item-content .product-description p.name {
  font-weight: 500;
  text-transform: uppercase;
}
section.section-recommended .carousel-content .item-content .product-description p.price {
  font-size: 11px;
}
section.section-recommended .carousel-content .item-content .product-description p.price span {
  font-size: 16px;
  font-weight: 700;
}
section.section-recommended .carousel-content .item-content .product-description .btn {
  font-size: 14px;
  padding: 3px 20px;
  margin: 10px 0;
}
section.section-gallery {
  padding: 70px 0;
  background: #fff;
}
section.section-gallery .gallery-flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
section.section-gallery .gallery-flexbox a {
  width: 17%;
  padding-top: 17%;
  overflow: hidden;
  position: relative;
  transition: all 200ms ease-in;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}
section.section-gallery .gallery-flexbox a.gallery-link {
  box-shadow: none;
  width: 19%;
  padding-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
section.section-gallery .gallery-flexbox a.gallery-link .heading {
  padding: 0;
  margin-bottom: 0;
  text-align: left;
  width: 100%;
}
section.section-gallery .gallery-flexbox a.gallery-link .heading h2 {
  transition: all 0.3s;
}
section.section-gallery .gallery-flexbox a.gallery-link:hover {
  transform: scale(1);
  box-shadow: none;
}
section.section-gallery .gallery-flexbox a.gallery-link:hover .heading h2 {
  color: #dfaf6c;
}
section.section-gallery .gallery-flexbox a:hover {
  box-shadow: 2px 12px 18px 0px rgba(0, 0, 0, 0.3);
  transform: scale(1.02);
}
section.section-gallery .gallery-flexbox a img {
  position: absolute;
  top: 0;
  left: 0;
}
section.section-gallery .gallery-flexbox .box-hidden {
  margin: 0;
  padding: 0;
  width: 17%;
  opacity: 0;
  visibility: hidden;
}
section.section-about {
  padding: 75px 0;
}
section.section-about .heading {
  margin: 0 20%;
}
section.section-about .heading h2 {
  margin-bottom: 20px;
}
section.section-footer {
  padding-top: 75px;
  background: #fff;
  position: relative;
  z-index: 100;
}
section.section-footer .footer-flexbox {
  display: flex;
  justify-content: space-between;
  position: relative;
}
section.section-footer .footer-flexbox .flexbox-service .service-logo {
  width: 170px;
}
section.section-footer .footer-flexbox .flexbox-service p {
  margin-top: 20px;
  font-size: 15px;
  color: #555;
  font-weight: 500;
  font-size: 14px;
}
section.section-footer .footer-flexbox .flexbox-service .footer-heading {
  font-size: 20px;
  font-weight: 500;
  color: #444;
  margin-bottom: 25px;
}
section.section-footer .footer-flexbox .flexbox-service ul li {
  margin-top: 10px;
  font-weight: 400;
}
section.section-footer .footer-flexbox .flexbox-service ul li a {
  color: #555;
  font-weight: 500;
  font-size: 15px;
  transition: all 250ms;
}
section.section-footer .footer-flexbox .flexbox-service ul li a b {
  font-size: 16px;
}
section.section-footer .footer-flexbox .flexbox-service ul li a span {
  font-size: 10px;
}
section.section-footer .footer-flexbox .flexbox-service ul li a:hover {
  color: #dfaf6c;
}
section.section-footer .footer-flexbox .flexbox-service .list-social {
  display: flex;
  align-items: center;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li {
  margin-top: 0;
  margin-right: 20px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li a img {
  height: 20px;
  transition: all 0.2s;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li a img:hover {
  transform: scale(1.1);
}
section.section-footer .footer-flexbox .flexbox-service .list-social li:last-child {
  margin-right: 0;
}
section.section-footer .footer-credits-wrapper {
  margin-top: 75px;
  background: #f4f4f4;
}
section.section-footer .footer-credits {
  border-top: none;
  margin: 0;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
section.section-footer .footer-credits p {
  font-size: 13px;
  color: #444;
  font-weight: 400;
}
section.section-footer .footer-credits p a {
  color: #dfaf6c;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: 700;
}
section.section-footer .footer-credits p a:hover {
  color: #212529;
}
section.section-footer .alert {
  position: relative;
  margin: 0;
  padding: 50px 0;
  border-radius: 0;
  background: none;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
section.section-footer .alert p {
  font-size: 13px;
  color: #444;
  font-weight: 400;
}
section.section-footer .alert p a {
  color: #dfaf6c;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: 700;
}
section.section-footer .alert p a:hover {
  color: #212529;
}
section.kategoria-section-content {
  padding: 60px 0;
}
section.kategoria-section-content .content-description p {
  margin-top: 20px;
}
section.kategoria-section-content.margin-index {
  margin-top: 0;
}
section.kategoria-section-content .breadcrumb {
  background-color: transparent;
  padding: 0;
  padding-bottom: 20px;
  margin: 0;
}
section.kategoria-section-content .breadcrumb li {
  font-size: 14px;
  display: inline-block;
  color: #3f4656;
}
section.kategoria-section-content .breadcrumb li.active {
  color: #dfaf6c;
}
section.kategoria-section-content .breadcrumb li a {
  color: #333;
}
section.kategoria-section-content .content-description {
  width: 100%;
}
section.kategoria-section-content .content-description h1 {
  color: #3f4656;
  margin-bottom: 20px;
  font-size: 26px;
}
section.kategoria-section-content .content-description p {
  font-size: 14px;
}
section.kategoria-section-content .content-flexbox {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}
section.kategoria-section-content .content-flexbox .flexbox-body {
  position: relative;
  width: 70%;
  z-index: 1;
}
section.kategoria-section-content .content-flexbox .flexbox-body h2 {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  color: #252429;
}
section.kategoria-section-content .content-flexbox .flexbox-body .empty-alert {
  margin-top: 30px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .pagination-nav {
  margin-top: 50px;
  padding-top: 25px;
  width: 100%;
}
section.kategoria-section-content .content-flexbox .flexbox-body .pagination-nav li {
  display: flex;
  align-items: center;
  justify-content: center;
}
section.kategoria-section-content .content-flexbox .flexbox-body .pagination-nav li a {
  display: block;
  width: 35px;
  height: 35px;
  font-size: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #444;
}
section.kategoria-section-content .content-flexbox .flexbox-body .pagination-nav li a.active {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
}
section.kategoria-section-content .content-flexbox .category-nav-button {
  outline: none;
  display: none;
  width: 100%;
  background: none;
}
section.kategoria-section-content .content-flexbox .category-nav-button-close {
  position: absolute;
  display: none;
  width: 100%;
  top: 20px;
  left: 0;
}
section.kategoria-section-content .content-flexbox .category-nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
section.kategoria-section-content .content-flexbox .category-nav-button-close .container:before,
section.kategoria-section-content .content-flexbox .category-nav-button-close .container:after {
  content: none;
}
section.kategoria-section-content .content-flexbox .category-nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
section.kategoria-section-content .content-flexbox .category-nav-button-close button .menu-bar {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  background: #222;
}
section.kategoria-section-content .content-flexbox .category-nav-button-close button .menu-bar:nth-child(1) {
  transform: rotate(45deg);
}
section.kategoria-section-content .content-flexbox .category-nav-button-close button .menu-bar:nth-child(2) {
  transform: rotate(-45deg);
}
section.kategoria-section-content .content-flexbox .products-flexbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 20px;
}
section.kategoria-section-content .content-flexbox .products-flexbox .product-service {
  position: relative;
  width: 30%;
  margin-top: 50px;
}
section.kategoria-section-content .content-flexbox .products-flexbox .product-service .service-image img {
  width: 100%;
  height: 150px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar {
  width: 23%;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar h3 {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  color: #252429;
  margin-bottom: 30px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav {
  padding-top: 16px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav li {
  margin-bottom: 15px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav li a {
  color: #444;
  font-size: 21px;
  font-weight: 300;
  transition: all 0.4s;
  display: flex;
  align-items: center;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav li a:hover {
  color: #dfaf6c;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav li:last-child {
  margin-bottom: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav li.active a {
  color: #dfaf6c;
  font-weight: 700;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav li.has-child .child-parent {
  color: #444;
  font-weight: 700;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav li.has-child .child-list {
  margin-top: 15px;
  padding-left: 30px;
  width: 100%;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav li.has-child .child-list li {
  margin-bottom: 10px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav li.has-child .child-list li a {
  font-size: 12px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav li.has-child .child-list li a:before {
  content: none;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav li.has-child .child-list li:last-child {
  margin-bottom: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .filter-buttons {
  margin-top: 40px;
  display: flex;
  align-items: center;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .filter-buttons .btn {
  color: #333;
  padding: 5px 15px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .filter-buttons .btn.btn-border {
  padding: 5px 30px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .filter-buttons .btn-cancel {
  margin-left: 20px;
  text-transform: uppercase;
  font-size: 12px;
  color: #111;
  border: none;
  padding: 0;
  background: none;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .description p {
  color: #666;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .description .btn {
  margin-top: 20px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .sidebar-posts .post {
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.35);
  margin-bottom: 20px;
  display: block;
  transition: box-shadow 0.4s;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .sidebar-posts .post .service-image-wrapper {
  width: 100%;
  padding-top: 40%;
  position: relative;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .sidebar-posts .post .service-image-wrapper .service-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .sidebar-posts .post .service-image-wrapper .service-image img {
  width: 100%;
}
div.produkt-section-content {
  padding-top: 40px;
}
div.produkt-section-content .breadcrumb {
  background-color: transparent;
  padding: 0;
  padding-bottom: 20px;
  margin: 0;
}
div.produkt-section-content .breadcrumb li {
  font-size: 14px;
  display: inline-block;
  color: #444;
}
div.produkt-section-content .breadcrumb li.active {
  color: #dfaf6c;
}
div.produkt-section-content .breadcrumb li a {
  color: #333;
}
div.produkt-section-content .content-flexbox {
  display: flex;
  justify-content: space-between;
  padding: 30px 0 60px 0;
}
div.produkt-section-content .content-flexbox .flexbox-body {
  width: 40%;
  display: flex;
  flex-direction: column;
}
div.produkt-section-content .content-flexbox .flexbox-body img {
  width: 100%;
  border: 3px solid #ddd;
  transition: all 250ms ease-in;
}
div.produkt-section-content .content-flexbox .flexbox-body img:hover {
  border: 3px solid #fff;
}
div.produkt-section-content .content-flexbox .gallery-product {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 30px;
}
div.produkt-section-content .content-flexbox .gallery-product a {
  margin: 5px;
  width: 23%;
  padding-top: 23%;
  overflow: hidden;
  border: 3px solid #ddd;
  transition: all 250ms ease-in;
}
div.produkt-section-content .content-flexbox .gallery-product a:hover {
  border: 3px solid #fff;
}
div.produkt-section-content .content-flexbox .gallery-product.main {
  width: 100%;
  margin-top: 0;
}
div.produkt-section-content .content-flexbox .gallery-product.main a {
  width: 100%;
  padding-top: 100%;
  border: 3px solid #ddd;
}
div.produkt-section-content .content-flexbox .gallery-product.main a:hover {
  border: 3px solid #fff;
}
div.produkt-section-content .content-flexbox .gallery-product .box-hidden {
  margin: 5px;
  padding: 0;
  width: 23%;
  opacity: 0;
  visibility: hidden;
}
div.produkt-section-content .content-flexbox .flexbox-sidebar {
  width: 50%;
}
div.produkt-section-content .content-flexbox .flexbox-sidebar h2 {
  font-weight: 700;
  color: #444;
  font-size: 35px;
  margin-bottom: 30px;
}
div.produkt-section-content .content-flexbox .flexbox-sidebar .manufacturer {
  color: #252429;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 20px 0;
}
div.produkt-section-content .content-flexbox .flexbox-sidebar .manufacturer a,
div.produkt-section-content .content-flexbox .flexbox-sidebar .manufacturer span {
  color: #252429;
}
div.produkt-section-content .content-flexbox .flexbox-sidebar .manufacturer a.zero,
div.produkt-section-content .content-flexbox .flexbox-sidebar .manufacturer span.zero {
  color: #444;
}
div.produkt-section-content .content-flexbox .flexbox-sidebar .specification {
  margin-top: 10px;
  color: #444;
  font-weight: 700;
  font-size: 14px;
  padding: 10px 0;
}
div.produkt-section-content .content-flexbox .flexbox-sidebar .specification span {
  display: inline-block;
  color: #252429;
  font-weight: 400;
  padding: 5px 0;
}
div.produkt-section-content .content-flexbox .flexbox-sidebar h3 {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  color: #252429;
}
div.produkt-section-content .content-flexbox .flexbox-sidebar .select-wrapper-flexbox {
  margin: 10px 0;
}
div.produkt-section-content .content-flexbox .flexbox-sidebar .select-wrapper-flexbox label {
  color: #444;
  font-size: 14px;
  font-weight: 400;
  width: 100px;
  margin-bottom: 0;
}
div.produkt-section-content .content-flexbox .flexbox-sidebar .select-wrapper-flexbox .select-wrapper {
  width: 300px;
}
div.produkt-section-content .content-flexbox .flexbox-sidebar .price {
  font-size: 26px;
  color: #444;
  font-weight: 600;
  padding: 20px 0;
}
div.produkt-section-content .content-flexbox .flexbox-sidebar .price#price_container {
  text-transform: uppercase;
  font-size: 14px;
  color: #444;
}
div.produkt-section-content .content-flexbox .flexbox-sidebar .price small {
  font-size: 16px;
  color: #666;
  margin-right: 5px;
}
div.produkt-section-content .content-flexbox .flexbox-sidebar .price span {
  position: relative;
  color: #999;
}
div.produkt-section-content .content-flexbox .flexbox-sidebar .price span:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: #444;
}
div.produkt-section-content .content-flexbox .flexbox-sidebar .price span.price-content {
  display: inline-block;
  margin-top: 20px;
  margin: 0 5px;
  font-size: 24px;
  color: #242529;
}
div.produkt-section-content .content-flexbox .flexbox-sidebar .price span.price-content:after {
  display: none;
}
div.produkt-section-content .content-flexbox .flexbox-sidebar .ck-editor-text {
  padding: 10px 0;
}
div.produkt-section-content .content-flexbox .flexbox-sidebar .ck-editor-text p {
  color: #242529;
  font-size: 14px;
}
div.produkt-section-content .content-recommended {
  padding: 60px 0 120px 0;
}
div.produkt-section-content .content-recommended h2 {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 600;
  color: #232429;
}
div.produkt-section-content .content-recommended .products-flexbox {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
}
div.produkt-section-content .content-recommended .products-flexbox .wrapper {
  height: 100%;
}
div.produkt-section-content .content-recommended .products-flexbox .product-service {
  position: relative;
  width: 22%;
  margin: 30px 10px 0 10px;
}
div.produkt-section-content .content-recommended .products-flexbox .product-service .badge {
  position: absolute;
  top: 0;
  z-index: 10;
  padding: 8px 12px;
  color: #fff;
  font-size: 13px;
  border-radius: 0;
  font-weight: 500;
}
div.produkt-section-content .content-recommended .products-flexbox .product-service .badge.bestseller {
  background: red;
}
div.produkt-section-content .content-recommended .products-flexbox .product-service .badge.new {
  background: #444;
  right: 0;
}
div.produkt-section-content .content-recommended .products-flexbox .product-service .service-image {
  text-align: center;
}
div.produkt-section-content .content-recommended .products-flexbox .product-service .service-image img {
  width: 100%;
}
div.produkt-section-content .section-cta-content {
  position: relative;
  z-index: 1;
  background-color: #fcfcfc;
  padding: 60px 0;
}
div.produkt-section-content .section-cta-content .heading {
  text-align: left;
  margin-bottom: 30px;
}
div.produkt-section-content .section-cta-content .wrapper {
  display: flex;
}
div.produkt-section-content .section-cta-content .wrapper .cta-text {
  width: 55%;
  padding-right: 50px;
}
div.produkt-section-content .section-cta-content .wrapper .cta-text .btn {
  margin-top: 30px;
}
div.produkt-section-content .section-cta-content .wrapper .cta-img {
  width: 45%;
  height: 350px;
  margin-top: -20%;
}
div.produkt-section-content .section-cta-content .wrapper .cta-img img {
  object-fit: cover;
  object-position: 50% 50%;
  height: 100%;
  width: 100%;
}
section.cennik-section-content {
  position: relative;
  padding: 60px 0;
}
section.cennik-section-content .table {
  margin-bottom: 0;
  margin-top: 60px;
  background-color: #f7f7f7;
  text-align: center;
}
section.cennik-section-content .table thead {
  color: #444;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
}
section.cennik-section-content .table thead tr th {
  padding: 20px;
  border: none;
  border-bottom: 2px solid #fff;
}
section.cennik-section-content .table thead tr th:first-child {
  width: 80px;
  border-bottom: none;
}
@media only screen and (max-width: 1366px) {
  section.cennik-section-content .table thead tr th:first-child {
    width: 60px;
  }
}
section.cennik-section-content .table thead tr th:nth-child(2) {
  min-width: 200px;
}
section.cennik-section-content .table thead tr th:nth-child(4) {
  width: 100px;
}
section.cennik-section-content .table thead tr th:last-child {
  width: 80px;
  border-bottom: none;
}
@media only screen and (max-width: 1366px) {
  section.cennik-section-content .table thead tr th:last-child {
    width: 60px;
  }
}
section.cennik-section-content .table tbody tr {
  border: 1px solid rgba(0, 0, 0, 0.08);
}
section.cennik-section-content .table tbody tr:nth-child(even) {
  background-color: #fff;
}
section.cennik-section-content .table tbody tr th,
section.cennik-section-content .table tbody tr td {
  border: none;
  font-size: 14px;
  vertical-align: middle;
  padding: 20px;
}
section.cennik-section-content .table tbody tr th span,
section.cennik-section-content .table tbody tr td span {
  font-size: 10px;
  color: #a0a0a0;
  margin-right: 15px;
}
section.cennik-section-content .table tbody tr th span:first-of-type,
section.cennik-section-content .table tbody tr td span:first-of-type {
  color: inherit;
  display: block;
  margin-right: 0;
  font-size: 14px;
}
section.cennik-section-content .table tbody tr th span.price,
section.cennik-section-content .table tbody tr td span.price {
  font-weight: 600;
}
section.cennik-section-content .download-file {
  display: flex;
  justify-content: center;
  padding-top: 50px;
}
section.gallery-section-content {
  padding: 60px 0 150px 0;
}
section.gallery-section-content .gallery {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 150px;
  grid-auto-flow: dense;
  padding-top: 30px;
}
section.gallery-section-content .gallery .fancybox-caption {
  font-size: 20px !important;
}
section.gallery-section-content .gallery a {
  transition: all 250ms ease-in-out;
}
section.gallery-section-content .gallery a:hover {
  box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.3);
  transform: scale(1.02);
}
section.gallery-section-content .gallery a.horizontal {
  grid-column: span 2;
}
section.gallery-section-content .gallery a.vertical {
  grid-row: span 2;
}
section.gallery-section-content .gallery a.big {
  grid-column: span 2;
  grid-row: span 2;
}
section.gallery-section-content .gallery a img {
  object-fit: cover;
  object-position: 50% 50%;
  height: 100%;
  width: 100%;
}
section.policy-section-content {
  padding: 60px 0 80px 0;
}
section.policy-section-content .text p {
  margin-top: 15px;
  font-size: 16px;
  line-height: 32px;
}
section.kontakt-section-content {
  padding: 60px 0 150px 0;
}
section.kontakt-section-content .kontakt-inner {
  height: 300px;
  width: 100%;
  display: flex;
  margin-top: 50px;
}
section.kontakt-section-content .kontakt-inner .inner-address {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
section.kontakt-section-content .kontakt-inner .inner-address address {
  font-size: 18px;
  margin-bottom: 20px;
}
section.kontakt-section-content .kontakt-inner .inner-address h3 {
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
}
section.kontakt-section-content .kontakt-inner .inner-address p,
section.kontakt-section-content .kontakt-inner .inner-address a {
  font-size: 18px;
  color: #212529;
}
section.kontakt-section-content .kontakt-inner .inner-address p {
  font-weight: 700;
  margin: 10px 0;
}
section.kontakt-section-content .kontakt-inner .iframe-map {
  width: 60%;
  height: 100%;
  max-height: 300px;
}
section.kontakt-section-content .kontakt-inner .iframe-map iframe {
  height: 100%;
  width: 100%;
  border: none;
}
@media (max-width: 1900px) {
  body .logo-img {
    right: -25%;
  }
}
@media (max-width: 1199.98px) {
  section.section-welcome .content-wrapper.left h1 {
    font-size: 44px;
  }
  section.section-about .logo-img {
    right: 0;
    bottom: -15%;
  }
}
@media (max-width: 991.98px) {
  section.section-welcome .welcome-flexbox .content-wrapper.left {
    min-height: 550px;
  }
  section.section-welcome .welcome-flexbox .content-wrapper.left h1 {
    font-size: 36px;
    line-height: 48px;
  }
  section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper .tiles .btn {
    align-self: center;
    padding: 5px 15px;
    font-size: 12px;
  }
  section.section-cta .cta-inner .img-absolute {
    bottom: 0;
    top: -20px;
    left: 0;
    height: 80%;
  }
  section.kontakt .kontakt-inner .iframe-map {
    margin-left: 20px;
  }
  .produkt-section-content .cta-text {
    width: 50%;
    margin-right: 10%;
  }
  .produkt-section-content .cta-img {
    width: 50%;
  }
}
@media (max-width: 767.98px) {
  nav.section-header .header-flexbox .nav-button {
    display: flex;
    margin-right: -10px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #fff;
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
    display: block;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    padding: 50px;
    margin-right: 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-menu-more {
    display: flex;
    flex-direction: column;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-menu-more .search {
    display: none;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-menu-more .search-input {
    position: static;
    display: block;
    padding-top: 40px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-menu-more .search-input input::-webkit-input-placeholder {
    font-size: 14px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-menu-more .search-input .input-search {
    transition: all 250ms ease-in;
    width: 180px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-menu-more .search-input .input-search:focus {
    width: 200px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-menu-more .search-input .input-search,
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-menu-more .search-input .button-search {
    display: block;
    position: static;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-menu-more a {
    position: static;
    padding-top: 30px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-menu-more a img {
    width: 22px;
    height: 22px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
    flex-direction: column;
    align-items: center;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
    padding: 10px 0;
    margin-right: 0 !important;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    font-size: 20px;
    font-weight: 400;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:last-of-type {
    margin-right: 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:last-of-type a {
    color: #dfaf6c;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper.active {
    opacity: 1;
    visibility: visible;
  }
  nav.section-header .header-flexbox .flexbox-menu-more {
    display: none;
  }
  .heading {
    margin-bottom: 30px;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .heading h2 {
    font-size: 36px;
  }
  section.section-welcome .welcome-flexbox .content-wrapper.left {
    min-height: 400px;
  }
  section.section-welcome .welcome-flexbox .content-wrapper.left h1 {
    font-size: 26px;
    line-height: 44px;
  }
  section.section-welcome .welcome-flexbox .content-wrapper.right .tiles-wrapper .tiles .btn {
    align-self: center;
    padding: 5px 10px;
  }
  section.section-icons-work {
    padding-top: 70px;
  }
  section.section-icons-work .icons-work-flexbox .flexbox-service {
    padding: 0 20px;
  }
  section.section-cta .cta-inner .img-absolute {
    top: 50px;
    height: 60%;
  }
  div.produkt-section-content .section-cta-content .wrapper .cta-text {
    width: 100%;
    margin-right: 0;
  }
  div.produkt-section-content .section-cta-content .wrapper .cta-img {
    display: none;
  }
  section.kontakt-section-content {
    padding: 60px 0;
  }
  section.kontakt-section-content .kontakt-inner {
    text-align: center;
    height: 100%;
    flex-direction: column;
  }
  section.kontakt-section-content .kontakt-inner .inner-address,
  section.kontakt-section-content .kontakt-inner .iframe-map {
    width: 100%;
  }
  section.kontakt-section-content .kontakt-inner .iframe-map {
    padding-top: 50px;
    height: 400px;
  }
  section.policy-section-content {
    text-align: center;
  }
  section.section-footer .footer-flexbox {
    flex-direction: column;
  }
  section.section-footer .flexbox-service {
    text-align: center;
    margin-top: 20px;
  }
  section.section-footer .flexbox-service:nth-of-type(4) {
    margin-top: 0;
  }
  section.section-footer .flexbox-service:nth-of-type(4) .footer-heading {
    display: none;
  }
  section.section-footer .flexbox-service .list-social {
    justify-content: center;
  }
}
@media (max-width: 595.98px) {
  body:before {
    content: "";
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    background-image: url('../img/backgrounds/test.jpg');
  }
  section.section-welcome .welcome-flexbox {
    flex-direction: column;
  }
  section.section-welcome .welcome-flexbox .content-wrapper.left {
    width: 100%;
    min-height: 250px;
  }
  section.section-welcome .welcome-flexbox .content-wrapper.left h1 {
    font-size: 28px;
  }
  section.section-welcome .welcome-flexbox .content-wrapper.right {
    display: none;
  }
  section.section-icons-work .icons-work-flexbox {
    flex-direction: column;
    align-items: center;
  }
  section.section-icons-work .icons-work-flexbox .flexbox-service {
    width: 100%;
    padding-bottom: 30px;
  }
  section.section-products {
    padding-top: 70px;
    text-align: center;
  }
  section.section-products .nav-pills {
    flex-direction: column;
  }
  .product-wrapper .product {
    width: 47%;
    text-align: left;
  }
  .product-wrapper .product .product-description .name {
    font-size: 15px;
  }
  .product-wrapper .product .product-description .price p {
    font-size: 14px;
  }
  section.section-cta {
    padding-top: 70px;
  }
  section.section-cta .cta-inner {
    padding: 50px 20px;
  }
  section.section-cta .cta-inner .text {
    width: 100%;
    text-align: center;
    padding: 0;
  }
  section.section-cta .cta-inner .text h2 {
    font-size: 26px;
  }
  section.section-cta .cta-inner::before {
    display: none;
  }
  section.section-cta .cta-inner .img {
    display: none;
  }
  section.section-icons {
    padding-top: 70px;
  }
  section.section-icons .icons-flexbox {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  section.section-icons .icons-flexbox .flexbox-service {
    width: 100%;
    padding-bottom: 30px;
  }
  section.section-recommended {
    padding-top: 70px;
  }
  section.section-gallery {
    padding: 50px;
  }
  section.section-gallery .gallery-flexbox {
    flex-direction: column;
  }
  section.section-gallery .gallery-flexbox a {
    width: 100%;
    padding-top: 100%;
    margin-top: 10px;
  }
  section.section-gallery .gallery-flexbox a.gallery-link {
    width: 100%;
  }
  section.section-gallery .gallery-flexbox a.gallery-link .heading {
    margin-bottom: 30px;
  }
  section.gallery-section-content {
    padding: 60px 0;
  }
  section.section-about {
    padding: 70px 0;
  }
  section.section-about .heading {
    margin: 0;
  }
  section.kategoria-section-content {
    padding: 0 0 40px 0;
  }
  section.kategoria-section-content .breadcrumb {
    padding: 0 0 20px 0;
  }
  section.kategoria-section-content .breadcrumb li {
    font-size: 10px;
  }
  section.kategoria-section-content .heading h1 {
    font-size: 32px;
  }
  section.kategoria-section-content .content-flexbox {
    flex-direction: column;
    padding-top: 0;
  }
  section.kategoria-section-content .content-flexbox .category-nav-button {
    display: flex;
    justify-content: center;
  }
  section.kategoria-section-content .content-flexbox .category-nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #fff;
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  section.kategoria-section-content .content-flexbox .category-nav-wrapper .category-nav-button-close {
    display: block;
  }
  section.kategoria-section-content .content-flexbox .category-nav-wrapper .category-nav-wrapper-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100%;
    padding: 50px;
  }
  section.kategoria-section-content .content-flexbox .category-nav-wrapper .category-nav-wrapper-inner .heading:first-of-type {
    margin-top: 0 !important;
  }
  section.kategoria-section-content .content-flexbox .category-nav-wrapper .category-nav-wrapper-inner .category-nav li a {
    justify-content: center;
  }
  section.kategoria-section-content .content-flexbox .category-nav-wrapper .category-nav-wrapper-inner .category-nav li.has-child .child-list {
    padding-left: 0px;
  }
  section.kategoria-section-content .content-flexbox .category-nav-wrapper .category-nav-wrapper-inner .filter-buttons {
    align-items: center;
  }
  section.kategoria-section-content .content-flexbox .category-nav-wrapper.active {
    opacity: 1;
    visibility: visible;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body {
    margin-top: 30px;
    width: 100%;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar {
    width: 100%;
  }
  div.produkt-section-content {
    padding-top: 0;
  }
  div.produkt-section-content .breadcrumb li {
    font-size: 10px;
  }
  div.produkt-section-content .section-cta-content .heading {
    text-align: center;
  }
  div.produkt-section-content .section-cta-content .wrapper .cta-text {
    text-align: center;
    padding-right: 0;
  }
  div.produkt-section-content .content-flexbox {
    flex-direction: column;
    padding: 0 0 20px 0;
  }
  div.produkt-section-content .content-flexbox .flexbox-body,
  div.produkt-section-content .content-flexbox .flexbox-sidebar {
    width: 100%;
    text-align: center;
  }
  div.produkt-section-content .content-flexbox .flexbox-body h2,
  div.produkt-section-content .content-flexbox .flexbox-sidebar h2 {
    font-size: 28px;
  }
  div.produkt-section-content .content-flexbox .flexbox-body {
    order: 2;
  }
  section.cennik-section-content .table thead tr th:nth-child(2) {
    min-width: 150px;
  }
  section.section-footer {
    padding-top: 30px;
  }
  section.section-footer .footer-credits {
    justify-content: center;
  }
  section.section-footer .footer-credits p {
    font-size: 10px;
    text-align: center;
  }
}
