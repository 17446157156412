@media(max-width: 1900px) {

	body {
		.logo-img {
			right: -25%;
		}
	}
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {

	section.section-welcome {
		.content-wrapper {
			&.left {
				h1 {
					font-size: 44px;
				}
			}
		}
	}

	section.section-about {
		.logo-img {
			right: 0;
			bottom: -15%;
		}
	}

}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {

	section.section-welcome {
		.welcome-flexbox {
			.content-wrapper {
				&.left {
					min-height: 550px;

					h1 {
						font-size: 36px;
						line-height: 48px;
					}
				}

				&.right {
					.tiles-wrapper {
						.tiles {
							.btn {
								align-self: center;
								padding: 5px 15px;
								font-size: 12px;
							}
						}
					}
				}
			}
		}
	}

	section.section-cta {
		.cta-inner {
			.img-absolute {
				bottom: 0;
				top: -20px;
				left: 0;
				height: 80%;
			}
		}
	}


	section.kontakt {
		.kontakt-inner {
			.iframe-map {
				margin-left: 20px;
			}
		}
	}

	.produkt-section-content {
		.cta-text {
			width: 50%;
			margin-right: 10%;
		}

		.cta-img {
			width: 50%;
		}
	}
}



// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {

	nav.section-header {
		.header-flexbox {
			.nav-button {
				display: flex;
				margin-right: -10px;
			}

			.flexbox-nav-wrapper {
				position: fixed;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				overflow-y: scroll;
				background: #fff;
				z-index: 555;
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s;

				.nav-button-close {
					display: block;
				}

				.flexbox-nav-inner {
					display: flex;
					flex-direction: column;
					justify-content: center;
					min-height: 100%;
					padding: 50px;
					margin-right: 0;

					.flexbox-menu-more {
						display: flex;
						flex-direction: column;

						.search {
							display: none;
						}

						.search-input {
							position: static;
							display: block;
							padding-top: 40px;

							input::-webkit-input-placeholder {
								font-size: 14px;
							}

							.input-search {
								transition: all 250ms ease-in;
								width: 180px;

								&:focus {
									width: 200px;
								}
							}

							.input-search,
							.button-search {
								display: block;
								position: static;
							}
						}

						a {
							position: static;
							padding-top: 30px;

							img {
								width: 22px;
								height: 22px;
							}
						}
					}

					.flexbox-nav {
						flex-direction: column;
						align-items: center;

						li {
							padding: 10px 0;
							margin-right: 0 !important;

							a {
								font-size: 20px;
								font-weight: 400;
							}

							&:last-of-type {
								margin-right: 0;

								a {
									color: @color1;
								}
							}
						}
					}
				}

				&.active {
					opacity: 1;
					visibility: visible;
				}
			}

			.flexbox-menu-more {
				display: none;
			}
		}
	}

	.heading {
		margin-bottom: 30px;
		margin-left: 0 !important;
		margin-right: 0 !important;

		h2 {
			font-size: 36px;
		}
	}

	section.section-welcome {
		.welcome-flexbox {
			.content-wrapper {
				&.left {
					min-height: 400px;

					h1 {
						font-size: 26px;
						line-height: 44px;
					}
				}

				&.right {
					.tiles-wrapper {
						.tiles {
							.btn {
								align-self: center;
								padding: 5px 10px;
							}
						}
					}
				}
			}
		}
	}

	section.section-icons-work {
		padding-top: 70px;

		.icons-work-flexbox {
			.flexbox-service {
				padding: 0 20px;
			}
		}
	}

	section.section-cta {
		.cta-inner {
			.img-absolute {
				top: 50px;
				height: 60%;
			}
		}
	}

	div.produkt-section-content {
		.section-cta-content {
			.wrapper {
				.cta-text {
					width: 100%;
					margin-right: 0;
				}

				.cta-img {
					display: none;
				}
			}
		}
	}



	// .product-wrapper {

	// 	.product,
	// 	.box-hidden {
	// 		width: 48%;
	// 	}
	// }

	// section.section-gallery {
	// 	.gallery-flexbox {
	// 		a.gallery-link {
	// 			width: 30%;
	// 		}
	// 	}
	// }

	section.kontakt-section-content {
		padding: 60px 0;

		.kontakt-inner {
			text-align: center;
			height: 100%;
			flex-direction: column;

			.inner-address,
			.iframe-map {
				width: 100%;
			}

			.iframe-map {
				padding-top: 50px;
				height: 400px;
			}
		}
	}

	section.policy-section-content {
		text-align: center;
	}


	section.section-footer {
		.footer-flexbox {
			flex-direction: column;
		}

		.flexbox-service {
			text-align: center;
			margin-top: 20px;

			&:nth-of-type(4) {
				margin-top: 0;

				.footer-heading {
					display: none;
				}
			}

			.list-social {
				justify-content: center;
			}
		}
	}
}

// Extra small devices (portrait phones, less than 595px)
@media (max-width: 595.98px) {

	body:before {
		content: "";
		display: block;
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: -10;
		background-image: url('../img/backgrounds/test.jpg');
	}

	section.section-welcome {
		.welcome-flexbox {
			flex-direction: column;

			.content-wrapper {
				&.left {
					width: 100%;
					min-height: 250px;

					h1 {
						font-size: 28px;
					}
				}

				&.right {
					display: none;
				}
			}
		}
	}

	section.section-icons-work {
		.icons-work-flexbox {
			flex-direction: column;
			align-items: center;

			.flexbox-service {
				width: 100%;
				padding-bottom: 30px;
			}
		}
	}

	section.section-products {
		padding-top: 70px;
		text-align: center;

		.nav-pills {
			flex-direction: column;
		}
	}

	.product-wrapper {
		.product {
			width: 47%;
			text-align: left;

			.product-description {
				.name {
					font-size: 15px;
				}

				.price {
					p {
						font-size: 14px;
					}
				}
			}
		}

	}

	section.section-cta {
		padding-top: 70px;

		.cta-inner {
			padding: 50px 20px;

			.text {
				width: 100%;
				text-align: center;
				padding: 0;

				h2 {
					font-size: 26px;
				}
			}

			&::before {
				display: none;
			}

			.img {
				display: none;
			}
		}
	}

	section.section-icons {
		padding-top: 70px;

		.icons-flexbox {
			flex-direction: column;
			align-items: center;
			margin-top: 20px;

			.flexbox-service {
				width: 100%;
				padding-bottom: 30px;
			}
		}
	}

	section.section-recommended {
		padding-top: 70px;
	}

	section.section-gallery {
		padding: 50px;

		.gallery-flexbox {
			flex-direction: column;

			a {
				width: 100%;
				padding-top: 100%;
				margin-top: 10px;
			}

			a.gallery-link {
				width: 100%;

				.heading {
					margin-bottom: 30px;
				}
			}
		}
	}

	section.gallery-section-content {
		padding: 60px 0;
	}

	section.section-about {
		padding: 70px 0;

		.heading {
			margin: 0;
		}
	}

	section.kategoria-section-content {
		padding: 0 0 40px 0;

		.breadcrumb {
			padding: 0 0 20px 0;

			li {
				font-size: 10px;
			}
		}

		.heading {
			h1 {
				font-size: 32px;
			}
		}

		.content-flexbox {
			flex-direction: column;
			padding-top: 0;

			.category-nav-button {
				display: flex;
				justify-content: center;
			}

			.category-nav-wrapper {
				position: fixed;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				overflow-y: scroll;
				background: #fff;
				z-index: 555;
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s;

				.category-nav-button-close {
					display: block;
				}

				.category-nav-wrapper-inner {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					text-align: center;
					min-height: 100%;
					padding: 50px;

					.heading {
						&:first-of-type {
							margin-top: 0 !important;
						}
					}

					.category-nav {
						li {
							a {
								justify-content: center;
							}

							&.has-child {
								.child-list {
									padding-left: 0px;
								}
							}
						}
					}

					.filter-buttons {
						align-items: center;
					}
				}

				&.active {
					opacity: 1;
					visibility: visible;
				}
			}

			.flexbox-body {
				margin-top: 30px;
				width: 100%;
			}

			.flexbox-sidebar {
				width: 100%;
			}
		}
	}

	div.produkt-section-content {
		padding-top: 0;

		.breadcrumb {
			li {
				font-size: 10px;
			}
		}

		.section-cta-content {
			.heading {
				text-align: center;
			}

			.wrapper {

				.cta-text {
					text-align: center;
					padding-right: 0;
				}
			}
		}

		.content-flexbox {
			flex-direction: column;
			padding: 0 0 20px 0;

			.flexbox-body,
			.flexbox-sidebar {
				width: 100%;
				text-align: center;

				h2 {
					font-size: 28px;
				}
			}

			.flexbox-body {
				order: 2;
			}
		}
	}

	section.cennik-section-content {
		.table {
			thead {
				tr {
					th {

						&:nth-child(2) {
							min-width: 150px;
						}
					}
				}
			}
		}
	}

	section.section-footer {
		padding-top: 30px;

		.footer-credits {
			justify-content: center;

			p {
				font-size: 10px;
				text-align: center;
			}
		}
	}
}