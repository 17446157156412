@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Audiowide&display=swap&subset=latin-ext');

body {
    min-height: 100vh;
    height: 100%;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: url('../img/backgrounds/test.jpg');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    overflow-x: hidden;

    &.no-scroll {
        overflow: hidden;
        max-height: 100vh;
    }

    ul,
    ol,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        padding: 0;
    }

    a {
        text-decoration: none !important;
    }

    .container-fluid {
        max-width: 1240px;
    }

    .mb-10 {
        margin-bottom: 10px;
    }

    p {
        color: #212529;
        font-size: 14px;
        line-height: 24px;
    }

    .logo-img {
        position: fixed;
        z-index: -1;
        top: 25%;
        right: -25%;
        height: 60%;
    }
}

@color1: #dfaf6c;

.btn {
    // display: none;
    border: none;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.5);
    border-radius: 0;
    padding: 10px 20px;
    color: #444;
    font-size: 14px;
    font-weight: 500;
    transition: all 200ms ease-in;

    &:hover {}

    &.btn-default {
        background: #fff;

        &:hover {
            border: none;
            box-shadow: none;
            background-color: #444;
            color: #fff;
        }
    }
}

.system-alerts {
    width: 100%;
    text-align: center;

    .alert {
        border-radius: 0;
        border: none;
        height: 60px;
        display: flex;
        align-items: center;
        opacity: 0.9;
        transition: all 0.4s;

        .alert-inner {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .img-hidden {
                visibility: hidden;
                opacity: 0;
                height: 18px;
            }

            p {
                color: #fff;
                padding: 0 50px;
            }

            button {
                color: #fff;
                opacity: 1;
                font-weight: 300;
                float: none;

                img {
                    height: 18px;
                }
            }
        }

        &.alert-success {
            background: #76ad21;
        }

        &.alert-info {
            background: #218ead;
        }

        &.alert-warning {
            background: #c52b20;
        }
    }
}

.modal-service-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: rgba(0, 0, 0, 0.5);
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;

    &.active {
        opacity: 1;
        visibility: visible;
    }

    .modal-service {
        padding: 50px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100%;

        .service-inner {
            background: #fff;
            width: 700px;
            max-width: 100%;
            padding: 50px 25px 25px 25px;
            text-align: center;
            position: relative;

            .service-button {
                position: absolute;
                top: 20px;
                right: 20px;
                border: none;
                background: none;
                padding: 15px;
                display: flex;
                justify-content: center;
                align-items: center;

                .button-bar {
                    display: block;
                    position: absolute;
                    width: 20px;
                    height: 2px;
                    background: #222;

                    &:nth-child(1) {
                        transform: rotate(45deg);
                    }

                    &:nth-child(2) {
                        transform: rotate(-45deg);
                    }
                }
            }

            .service-content {
                .heading {
                    font-size: 16px;
                    font-weight: 700;
                    text-transform: uppercase;
                    margin-bottom: 20px;
                }

                .btn {
                    display: flex;
                    justify-content: center;
                    margin-top: 5px;
                }
            }

            .service-buttons {
                margin-top: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .btn-link {
                    padding: 0;
                    text-transform: uppercase;
                    font-weight: 700;
                    text-decoration: none;
                }
            }
        }
    }
}

.heading {
    text-align: center;
    margin-bottom: 40px;

    h2 {
        font-size: 42px;
        font-weight: 500;
        color: #444;
    }

    p {
        font-weight: 500;
        color: #999;
        margin-top: 10px;
        font-size: 16px;
    }

}

.product-wrapper {
    display: flex;

    .tab-content {
        display: flex;
        justify-content: space-between;
        padding: 0;
        width: 100%;
    }

    .tab-pane {
        width: 100%;
    }

    .wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
    }

    .product {
        margin-top: 3%;
        width: 22.5%;
        height: 100%;
        transition: all 0.4s;

        &:hover {
            .bg-product .product-image {
                border: 3px solid #fff;
            }
        }

        &.offer-more {
            .product-image {
                width: 100%;
                padding-top: 100%;
                position: relative;
                text-align: center;
                transition: all 0.4s;
                border: 3px solid #ddd;

                p {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 28px;
                    font-weight: 700;
                    color: rgba(0, 0, 0, 0.3);
                    line-height: 28px;

                    span {
                        font-size: 14px;
                        color: #212529;
                        font-weight: 400;
                    }
                }
            }

            &:hover .product-image {
                border: 3px solid #fff;
            }
        }

        .bg-product {

            // background-color: #fff;
            .product-image {
                border: 3px solid #ddd;
                transition: all 0.4s;
                width: 100%;
                padding-top: 100%;
                background-size: cover;
            }
        }

        .product-description {
            .name {
                font-size: 17px;
                margin-top: 20px;
                font-weight: 500;
                color: #444;
            }

            .price {
                margin-top: 5px;
                display: flex;
                align-items: center;

                img {
                    height: 18px;
                    margin-right: 8px;
                    width: auto;
                }

                p {
                    font-size: 16px;
                    font-weight: 500;
                    color: #999;
                }
            }

            .btn {
                font-size: 14px;
                padding: 3px 20px;
                margin: 10px 0;
            }
        }

        &.box-hidden {
            margin: 0;
            padding: 0;
            opacity: 0;
            visibility: hidden;
        }
    }
}


.nav-pills {
    align-items: center;
    flex-wrap: wrap;

    h3 {
        font-size: 15px;
        font-weight: 500;
        color: #444;
    }

    .nav-item {
        .nav-button {
            color: #444;
            font-size: 14px;
            text-decoration: none;
            font-weight: 600;
            opacity: 0.4;
            display: block;
            padding: 10px 15px;
            margin: 5px;
            transition: opacity 150ms ease-in;
            box-sizing: border-box;
            border: 1px solid transparent;

            &:nth-of-type(1) {
                margin: 5px 5px 5px 0;
            }


            &:hover {
                opacity: 1;

            }

            &.active {
                opacity: 1;
                border: 1px solid #444;
            }
        }
    }
}

nav.section-header {
    position: relative;
    z-index: 200;

    .container:before {
        display: none;
    }

    .header-flexbox {
        padding: 32px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .flexbox-logo {
            img {
                width: 200px;
            }
        }

        .flexbox-nav-wrapper {
            .nav-button-close {
                position: absolute;
                display: none;
                width: 100%;
                top: 20px;
                left: 0;

                .container {
                    display: flex;
                    justify-content: flex-end;

                    &:before,
                    &:after {
                        content: none;
                    }
                }

                button {
                    outline: none;
                    border: none;
                    background: none;
                    padding: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .button-bar {
                        display: block;
                        position: absolute;
                        width: 30px;
                        height: 2px;
                        background: #222;

                        &:nth-child(1) {
                            transform: rotate(45deg);
                        }

                        &:nth-child(2) {
                            transform: rotate(-45deg);
                        }
                    }
                }
            }

            .flexbox-nav-inner {
                .flexbox-nav {
                    display: flex;

                    li {
                        a {
                            font-size: 15px;
                            font-weight: 500;
                            color: #555;
                            transition: color 200ms ease-in;

                            &:hover {
                                color: @color1;
                            }

                            &.active {
                                color: @color1;
                            }

                            @media (max-width: 576px) {
                                margin-right: 0;

                                &:last-child {
                                    margin-right: 0;
                                }
                            }
                        }

                        &:not(:last-child) {
                            margin-right: 35px;
                        }

                    }
                }
            }
        }

        .search-input {
            position: absolute;
            right: 10px;

            form {
                display: flex;
                justify-content: center;

                .input-search {
                    border: none;
                    background: none;
                    border-bottom: 1px solid rgba(150, 10, 10, 0.2);
                    width: 160px;
                    display: none;
                    outline: none;

                    &.active {
                        display: block;
                        transition: all 270ms ease-in;
                    }

                    &:focus {
                        outline: none;
                        width: 170px;
                    }
                }

                input::-webkit-input-placeholder {
                    font-size: 13px;
                }

                .button-search {
                    position: absolute;
                    outline: none;
                    border: none;
                    background: transparent;
                    left: 0;
                    top: 0;
                    right: 0;
                    padding: 0;
                    margin: 0;
                    width: 15.5px;
                    height: 15.5px;
                    cursor: pointer;
                    display: none;

                    &.active {
                        display: block;
                        left: 145px;

                        img {
                            height: 13px;
                        }
                    }
                }
            }
        }

        .flexbox-menu-more {
            position: relative;
            display: flex;
            align-items: center;

            &.hide {
                display: none;
            }

            a {
                position: absolute;
                right: 0;

                img {
                    height: 15px;
                }
            }

            .search {
                position: relative;
                cursor: pointer;
                margin-right: 35px;

                .search-icon {
                    transition: transform 200ms ease-in;
                    width: 16px;
                    height: 16px;

                    &:hover {
                        transform: scale(1.1);
                    }

                    &.hover {
                        display: none;
                    }
                }
            }
        }

        .nav-button {
            padding: 10px;
            border: none;
            background: none;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            outline: none;
            display: none;

            .button-bar {
                height: 2px;
                width: 24px;
                background: @color1;
                border-radius: 10px;
                transition: all 0.4s;

                &:nth-child(2) {
                    margin-top: 4px;
                    width: 27px;
                }

                &:nth-child(3) {
                    margin-top: 4px;
                    width: 30px;
                }
            }

            &:hover,
            &:focus {
                .button-bar {
                    width: 30px !important;
                }
            }
        }
    }
}

section.section-welcome {
    .welcome-flexbox {
        display: flex;
        justify-content: space-between;

        .content-wrapper {
            width: 50%;
            display: flex;

            &.left {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                width: 49%;
                min-height: 600px;
                padding: 30px;

                img {
                    object-fit: cover;
                    object-position: 20% 60%;
                    height: 100%;
                    width: 100%;
                }

                h1 {
                    font-size: 46px;
                    line-height: 64px;
                    font-weight: 700;
                }

                .btn {
                    align-self: flex-start;
                }
            }

            &.right {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex-wrap: wrap;
                width: 48%;

                .tiles-wrapper {
                    display: flex;
                    justify-content: space-between;
                    height: 48%;
                    width: 100%;

                    .item {
                        height: 100%;
                    }

                    .tiles {
                        width: 47%;
                        border: 3px solid #ddd;
                    }

                    .tiles,
                    .item {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        padding: 30px;
                        position: relative;
                        overflow: hidden;

                        &.no-padding {
                            padding: 0;

                        }

                        .owl-carousel,
                        .owl-stage,
                        .owl-stage-outer,
                        .owl-item {
                            height: 100%;
                        }

                        img {
                            position: absolute;
                            width: auto;
                        }

                        .btn {
                            position: relative;
                            align-self: flex-start;
                        }
                    }

                    &:first-of-type {
                        .tiles:first-of-type {
                            .item:first-of-type {
                                img {
                                    bottom: -3%;
                                    height: 100%;
                                    right: -10%;
                                }
                            }

                            .item:nth-of-type(2) {
                                img {
                                    bottom: -2%;
                                    height: 100%;
                                    right: -10%;
                                }
                            }

                            .item:nth-of-type(2) {
                                img {
                                    bottom: -2%;
                                    height: 50%;
                                    right: -10%;
                                }
                            }
                        }

                        .tiles:nth-of-type(2) {
                            img {
                                height: 100%;
                                right: -27%;
                                top: 3%;
                            }

                        }
                    }

                    &:nth-of-type(2) {
                        .tiles:first-of-type {
                            img {
                                top: 5%;
                                height: 90%;
                                right: -7%;
                            }
                        }

                        .tiles:nth-of-type(2) {
                            .item:first-of-type {
                                img {
                                    height: 100%;
                                    right: -12%;
                                    top: 0;
                                }
                            }

                            .item:nth-of-type(2) {
                                img {
                                    height: 100%;
                                    right: -12%;
                                    top: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


section.section-icons-work {
    padding-top: 80px;

    .heading {
        text-align: center;
    }

    .icons-work-flexbox {
        display: flex;
        justify-content: space-between;

        .flexbox-service {
            width: calc(100% / 3);
            text-align: center;
            padding: 0 50px;

            .icon {
                height: 60px;
                width: 60px;
                margin: 0 auto;
                border-radius: 50%;
                background: #fff;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    height: 25px;
                }
            }

            h3 {
                font-size: 20px;
                margin-top: 20px;
                font-weight: 500;
                color: #444;
            }

            p {
                margin-top: 18px;
                font-size: 14px;
                font-weight: 500;
                color: #999;
            }
        }
    }
}

section.section-products {
    padding-top: 85px;

    .heading {
        margin-left: 20%;
        margin-right: 20%;
    }

    .nav-pills-wrapper {
        margin-bottom: 20px;

        p {
            font-size: 15px;
            font-weight: 500;
            padding-bottom: 10px;
        }
    }
}

section.section-icons {
    padding-top: 75px;

    .icons-flexbox {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;

        .flexbox-service {
            width: calc(100% / 5);
            text-align: center;

            .icon {
                height: 60px;
                width: 60px;
                margin: 0 auto;
                border-radius: 50%;
                background: #fff;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    height: 25px;
                }
            }

            h3 {
                font-size: 15px;
                margin-top: 20px;
                font-weight: 500;
                color: #444;
            }
        }
    }
}

section.section-cta {
    padding-top: 100px;

    .cta-inner {
        padding: 50px;
        background: #fff;
        position: relative;
        display: flex;
        align-items: center;

        .text {
            width: 60%;
            padding-right: 50px;

            h2 {
                font-size: 28px;
                font-weight: 500;
                color: #444;
            }

            h3 {
                font-size: 20px;
                margin-top: 10px;
                font-weight: 500;
                color: #666;
            }

            p {
                margin-top: 15px;
                font-size: 15px;
                font-weight: 500;
                color: #999;
            }

        }

        .img {
            width: 40%;
            height: 350px;
            margin-top: -10%;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: 50% 50%;

            }
        }

        // .img-absolute {
        // 	position: absolute;
        // 	left: 50px;
        // 	top: -30px;
        // 	height: 100%;
        // }

        .btn {
            margin-top: 30px;
        }
    }
}

section.section-recommended {
    padding-top: 80px;

    .heading {
        margin-left: 20%;
        margin-right: 20%;
    }

    .carousel-content {
        display: flex;

        .owl-carousel {
            .owl-stage-outer {
                padding: 0 2px;
            }

            .owl-nav {
                display: none;
            }

            .owl-stage {
                display: flex;
                padding: 0;
                width: 100%;
            }

            .owl-dots {
                display: flex;
                justify-content: center;
                padding-top: 40px;

                .owl-dot {
                    padding: 0px 1px 50px 1px;

                    span {
                        border-radius: 10px;
                        width: 70px;
                        height: 3px;
                        margin: 0;
                        transition: all 0.3s;
                    }
                }
            }

            .owl-dot.active {
                span {
                    background: @color1;
                }
            }
        }

        .product-wrapper,
        .wrapper {
            width: 100%;
        }

        .item-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background: #fff;
            border: 1px solid #ddd;
            box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.1);
            transition: box-shadow 200ms ease-in;

            &:hover {
                box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.2);
            }

            .product-description {
                padding: 5px 8px;
                text-align: center;
                background-color: #f6f6f6;

                p {
                    font-size: 13px;
                    color: #444;
                    margin-top: 8px;

                    &.name {
                        font-weight: 500;
                        text-transform: uppercase;
                    }

                    &.price {
                        font-size: 11px;

                        span {
                            font-size: 16px;
                            font-weight: 700;
                        }
                    }
                }

                .btn {
                    font-size: 14px;
                    padding: 3px 20px;
                    margin: 10px 0;
                }
            }
        }
    }
}

section.section-gallery {
    padding: 70px 0;
    background: #fff;

    .gallery-flexbox {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        a {
            width: 17%;
            padding-top: 17%;
            overflow: hidden;
            position: relative;
            transition: all 200ms ease-in;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);

            &.gallery-link {
                box-shadow: none;
                width: 19%;
                padding-top: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                .heading {
                    padding: 0;
                    margin-bottom: 0;
                    text-align: left;
                    width: 100%;

                    h2 {
                        transition: all 0.3s;
                    }
                }

                &:hover {
                    transform: scale(1);
                    box-shadow: none;

                    .heading {
                        h2 {
                            color: @color1;
                        }
                    }
                }
            }

            &:hover {
                box-shadow: 2px 12px 18px 0px rgba(0, 0, 0, 0.3);
                transform: scale(1.02);
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        .box-hidden {
            margin: 0;
            padding: 0;
            width: 17%;
            opacity: 0;
            visibility: hidden;
        }
    }
}

section.section-about {
    padding: 75px 0;

    .heading {
        margin: 0 20%;

        h2 {
            margin-bottom: 20px;
        }
    }
}

section.section-footer {
    padding-top: 75px;
    background: #fff;
    position: relative;
    z-index: 100;

    .footer-flexbox {
        display: flex;
        justify-content: space-between;
        position: relative;

        .flexbox-service {
            .service-logo {
                width: 170px;
            }

            p {
                margin-top: 20px;
                font-size: 15px;
                color: #555;
                font-weight: 500;
                font-size: 14px;
            }

            .footer-heading {
                font-size: 20px;
                font-weight: 500;
                color: #444;
                margin-bottom: 25px;
            }

            ul {

                li {
                    margin-top: 10px;
                    font-weight: 400;


                    a {
                        color: #555;
                        font-weight: 500;
                        font-size: 15px;
                        transition: all 250ms;

                        b {
                            font-size: 16px;
                        }

                        span {
                            font-size: 10px;
                        }

                        &:hover {
                            color: @color1;
                        }
                    }
                }
            }

            .list-social {
                display: flex;
                align-items: center;

                li {
                    margin-top: 0;
                    margin-right: 20px;

                    a {
                        img {
                            height: 20px;
                            transition: all 0.2s;

                            &:hover {
                                transform: scale(1.1);
                            }
                        }
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .footer-credits-wrapper {
        margin-top: 75px;
        background: #f4f4f4;
    }

    .footer-credits {
        border-top: none;
        margin: 0;
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        p {
            font-size: 13px;
            color: #444;
            font-weight: 400;

            a {
                color: @color1;
                cursor: pointer;
                transition: all 0.3s;
                font-weight: 700;

                &:hover {
                    color: #212529;
                }
            }
        }
    }

    .alert {
        position: relative;
        margin: 0;
        padding: 50px 0;
        border-radius: 0;
        background: none;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        p {
            font-size: 13px;
            color: #444;
            font-weight: 400;

            a {
                color: @color1;
                cursor: pointer;
                transition: all 0.3s;
                font-weight: 700;

                &:hover {
                    color: #212529;
                }
            }
        }
    }
}

section.kategoria-section-content {
    padding: 60px 0;

    .content-description {
        p {
            margin-top: 20px;
        }
    }

    &.margin-index {
        margin-top: 0;
    }

    .breadcrumb {
        background-color: transparent;
        padding: 0;
        padding-bottom: 20px;
        margin: 0;

        li {
            font-size: 14px;
            display: inline-block;
            color: #3f4656;

            &.active {
                color: @color1;
            }

            a {
                color: #333;
            }
        }
    }

    .content-description {
        width: 100%;

        h1 {
            color: #3f4656;
            margin-bottom: 20px;
            font-size: 26px;
        }

        p {
            font-size: 14px;
        }
    }

    .content-flexbox {
        display: flex;
        justify-content: space-between;
        padding-top: 30px;

        .flexbox-body {
            position: relative;
            width: 70%;
            z-index: 1;

            h2 {
                font-size: 18px;
                text-transform: uppercase;
                font-weight: 600;
                color: #252429;
            }

            .empty-alert {
                margin-top: 30px;
            }

            .pagination-nav {
                margin-top: 50px;
                padding-top: 25px;
                width: 100%;

                li {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    a {
                        display: block;
                        width: 35px;
                        height: 35px;
                        font-size: 18px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        color: #444;

                        &.active {
                            background-color: rgba(0, 0, 0, 0.3);
                            color: #fff;
                        }
                    }
                }
            }
        }

        .category-nav-button {
            outline: none;
            display: none;
            width: 100%;
            background: none;
        }

        .category-nav-button-close {
            position: absolute;
            display: none;
            width: 100%;
            top: 20px;
            left: 0;

            .container {
                display: flex;
                justify-content: flex-end;

                &:before,
                &:after {
                    content: none;
                }
            }

            button {
                outline: none;
                border: none;
                background: none;
                padding: 15px;
                display: flex;
                justify-content: center;
                align-items: center;

                .menu-bar {
                    display: block;
                    position: absolute;
                    width: 30px;
                    height: 2px;
                    background: #222;

                    &:nth-child(1) {
                        transform: rotate(45deg);
                    }

                    &:nth-child(2) {
                        transform: rotate(-45deg);
                    }
                }
            }
        }

        .products-flexbox {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-end;
            padding-top: 20px;

            .product-service {
                position: relative;
                width: 30%;
                margin-top: 50px;

                .service-image {
                    img {
                        width: 100%;
                        height: 150px;
                    }
                }
            }
        }

        .flexbox-sidebar {
            width: 23%;

            h3 {
                font-size: 18px;
                text-transform: uppercase;
                font-weight: 600;
                color: #252429;
                margin-bottom: 30px;
            }

            .category-nav {
                padding-top: 16px;

                li {
                    margin-bottom: 15px;

                    a {
                        color: #444;
                        font-size: 21px;
                        font-weight: 300;
                        transition: all 0.4s;
                        display: flex;
                        align-items: center;

                        &:hover {
                            color: @color1;
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &.active {
                        a {
                            color: @color1;
                            font-weight: 700;
                        }
                    }

                    &.has-child {
                        .child-parent {
                            color: #444;
                            font-weight: 700;
                        }

                        .child-list {
                            margin-top: 15px;
                            padding-left: 30px;
                            width: 100%;

                            li {
                                margin-bottom: 10px;

                                a {
                                    font-size: 12px;

                                    &:before {
                                        content: none;
                                    }
                                }

                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }

            .filter-buttons {
                margin-top: 40px;
                display: flex;
                align-items: center;

                .btn {
                    color: #333;
                    padding: 5px 15px;

                    &.btn-border {
                        padding: 5px 30px;
                    }
                }

                .btn-cancel {
                    margin-left: 20px;
                    text-transform: uppercase;
                    font-size: 12px;
                    color: #111;
                    border: none;
                    padding: 0;
                    background: none;
                }
            }

            .description {
                p {
                    color: #666;
                }

                .btn {
                    margin-top: 20px;
                }
            }

            .sidebar-posts {
                .post {
                    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.35);
                    margin-bottom: 20px;
                    display: block;
                    transition: box-shadow 0.4s;

                    .service-image-wrapper {
                        width: 100%;
                        padding-top: 40%;
                        position: relative;

                        .service-image {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            overflow: hidden;

                            img {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

div.produkt-section-content {
    padding-top: 40px;

    .breadcrumb {
        background-color: transparent;
        padding: 0;
        padding-bottom: 20px;
        margin: 0;

        li {
            font-size: 14px;
            display: inline-block;
            color: #444;

            &.active {
                color: @color1;
            }

            a {
                color: #333;
            }
        }
    }

    .content-flexbox {
        display: flex;
        justify-content: space-between;
        padding: 30px 0 60px 0;

        .flexbox-body {
            width: 40%;
            display: flex;
            flex-direction: column;

            img {
                width: 100%;
                border: 3px solid #ddd;
                transition: all 250ms ease-in;

                &:hover {
                    border: 3px solid #fff;
                }
            }
        }

        .gallery-product {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-top: 30px;

            a {
                margin: 5px;
                width: 23%;
                padding-top: 23%;
                overflow: hidden;
                border: 3px solid #ddd;
                transition: all 250ms ease-in;

                &:hover {
                    border: 3px solid #fff;
                }
            }

            &.main {
                width: 100%;
                margin-top: 0;

                a {
                    width: 100%;
                    padding-top: 100%;
                    border: 3px solid #ddd;

                    &:hover {
                        border: 3px solid #fff;
                    }
                }
            }

            .box-hidden {
                margin: 5px;
                padding: 0;
                width: 23%;
                opacity: 0;
                visibility: hidden;
            }
        }

        .flexbox-sidebar {
            width: 50%;

            h2 {
                font-weight: 700;
                color: #444;
                font-size: 35px;
                margin-bottom: 30px;
            }

            .manufacturer {
                color: #252429;
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 600;
                padding: 20px 0;

                a,
                span {
                    color: #252429;

                    &.zero {
                        color: #444;
                    }
                }
            }

            .specification {
                margin-top: 10px;
                color: #444;
                font-weight: 700;
                font-size: 14px;
                padding: 10px 0;

                span {
                    display: inline-block;
                    color: #252429;
                    font-weight: 400;
                    padding: 5px 0;
                }
            }

            h3 {
                font-size: 16px;
                text-transform: uppercase;
                font-weight: 600;
                margin: 10px 0;
                color: #252429;
            }

            .select-wrapper-flexbox {
                margin: 10px 0;

                label {
                    color: #444;
                    font-size: 14px;
                    font-weight: 400;
                    width: 100px;
                    margin-bottom: 0;
                }

                .select-wrapper {
                    width: 300px;
                }
            }

            .price {
                font-size: 26px;
                color: #444;
                font-weight: 600;
                padding: 20px 0;

                &#price_container {
                    text-transform: uppercase;
                    font-size: 14px;
                    color: #444;
                }

                small {
                    font-size: 16px;
                    color: #666;
                    margin-right: 5px;
                }

                span {
                    position: relative;
                    color: #999;

                    &:after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 1px;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        background: #444;
                    }

                    &.price-content {
                        display: inline-block;
                        margin-top: 20px;
                        margin: 0 5px;
                        font-size: 24px;
                        color: #242529;

                        &:after {
                            display: none;
                        }
                    }
                }
            }

            .ck-editor-text {
                padding: 10px 0;

                p {
                    color: #242529;
                    font-size: 14px;
                }
            }
        }
    }

    .content-recommended {
        padding: 60px 0 120px 0;

        h2 {
            font-size: 24px;
            text-transform: uppercase;
            font-weight: 600;
            color: #232429;
        }

        .products-flexbox {
            display: flex;
            flex-wrap: wrap;
            padding-top: 20px;

            .wrapper {
                height: 100%;
            }

            .product-service {
                position: relative;
                width: 22%;
                margin: 30px 10px 0 10px;

                .badge {
                    position: absolute;
                    top: 0;
                    z-index: 10;
                    padding: 8px 12px;
                    color: #fff;
                    font-size: 13px;
                    border-radius: 0;
                    font-weight: 500;

                    &.bestseller {
                        background: red;
                    }

                    &.new {
                        background: #444;
                        right: 0;
                    }
                }

                .service-image {
                    text-align: center;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    .section-cta-content {
        position: relative;
        z-index: 1;
        background-color: #fcfcfc;
        padding: 60px 0;

        .heading {
            text-align: left;
            margin-bottom: 30px;
        }

        .wrapper {
            display: flex;

            .cta-text {
                width: 55%;
                padding-right: 50px;

                .btn {
                    margin-top: 30px;
                }
            }

            .cta-img {
                width: 45%;
                height: 350px;
                margin-top: -20%;

                img {
                    object-fit: cover;
                    object-position: 50% 50%;
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
}

section.cennik-section-content {
    position: relative;
    padding: 60px 0;

    .table {
        margin-bottom: 0;
        margin-top: 60px;
        background-color: #f7f7f7;
        text-align: center;

        thead {
            color: #444;
            font-weight: 700;
            text-transform: uppercase;
            background-color: #fff;
            border: 1px solid rgba(0, 0, 0, 0.08);

            tr {
                th {
                    padding: 20px;
                    border: none;
                    border-bottom: 2px solid #fff;

                    &:first-child {
                        width: 80px;
                        border-bottom: none;

                        @media only screen and (max-width: 1366px) {
                            width: 60px;
                        }
                    }

                    &:nth-child(2) {
                        min-width: 200px;
                    }

                    &:nth-child(4) {
                        width: 100px;
                    }

                    &:last-child {
                        width: 80px;
                        border-bottom: none;

                        @media only screen and (max-width: 1366px) {
                            width: 60px;
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                border: 1px solid rgba(0, 0, 0, 0.08);

                &:nth-child(even) {
                    background-color: #fff;
                }

                th,
                td {
                    border: none;
                    font-size: 14px;
                    vertical-align: middle;
                    padding: 20px;

                    span {
                        font-size: 10px;
                        color: #a0a0a0;
                        margin-right: 15px;

                        &:first-of-type {
                            color: inherit;
                            display: block;
                            margin-right: 0;
                            font-size: 14px;
                        }

                        &.price {
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }

    .download-file {
        display: flex;
        justify-content: center;
        padding-top: 50px;
    }
}

section.gallery-section-content {
    padding: 60px 0 150px 0;

    .gallery {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        grid-auto-rows: 150px;
        grid-auto-flow: dense;
        padding-top: 30px;

        .fancybox-caption {
            font-size: 20px !important;
        }

        a {
            transition: all 250ms ease-in-out;

            &:hover {
                box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.3);
                transform: scale(1.02);
            }

            &.horizontal {
                grid-column: span 2;
            }

            &.vertical {
                grid-row: span 2;
            }

            &.big {
                grid-column: span 2;
                grid-row: span 2;
            }

            img {
                object-fit: cover;
                object-position: 50% 50%;
                height: 100%;
                width: 100%;
            }
        }
    }
}

section.policy-section-content {
    padding: 60px 0 80px 0;

    .text {
        p {
            margin-top: 15px;
            font-size: 16px;
            line-height: 32px;
        }
    }
}

section.kontakt-section-content {
    padding: 60px 0 150px 0;

    .kontakt-inner {
        height: 300px;
        width: 100%;
        display: flex;
        margin-top: 50px;

        .inner-address {
            width: 40%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            address {
                font-size: 18px;
                margin-bottom: 20px;
            }

            h3 {
                font-size: 24px;
                line-height: 28px;
                text-transform: uppercase;
            }

            p,
            a {
                font-size: 18px;
                color: #212529;
            }

            p {
                font-weight: 700;
                margin: 10px 0;
            }
        }

        .iframe-map {
            width: 60%;
            height: 100%;
            max-height: 300px;

            iframe {
                height: 100%;
                width: 100%;
                border: none;
            }
        }
    }
}